.footer-top {
    background-color: #075e54;
}
.footer-top img.download-button {
    max-height: 55px;
}
.footer-top p {
    color: white;
}
.footer-top ul {
    list-style: none;
    padding: 0;
}
.footer-top a.social-icon {
    font-size: 18px;
    display: inline-block;
    margin-right: 10px;
    color: whitesmoke;
    transition: all 0.3s ease-in;
}
.footer-top a.social-icon:hover {
    color: black;
}

.footer-wave > svg {
    position: relative;
    background: #ffffff;
    color: #000000;
    text-align: center;
    overflow: hidden;
    display: block;
    margin-bottom: -100px;
}

@media (max-width: 480px) {
    .footer-wave > svg {
        margin-bottom: 0px;
    }
}
footer a {
    color: white;
}
footer a:hover {
    color: white;
    text-decoration: underline;
}

.icon-wrapper {
    display: inline-block;
}
