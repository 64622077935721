@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

body {
    margin: 0;
    font-family: "Varela Round", sans-serif;
    /* font-family: "Noto Sans"; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
p {
    font-size: 18px;
}
.font-20 {
    font-size: 20px;
}
.font-22 {
    font-size: 22px;
}
.display-4 {
    font-size: 2.3rem;
}
.display-3 {
    font-size: 1.8rem;
    line-height: 2.4rem;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
body::-webkit-scrollbar {
    width: 0.5em;
}

body::-webkit-scrollbar-track {
    border-left: 1px solid rgba(0, 0, 0, 0.384);
    -webkit-box-shadow: inset 0 0 6px #075e546b;
}

body::-webkit-scrollbar-thumb {
    background-color: #0f92836b;
    border-radius: 30px;
}
.section-card {
    border-radius: 8px;
    background-color: #e9ecef;
}

.font-lato {
    font-family: "Lato", sans-serif;
}

.bottom-gray {
    /* border-bottom: 1px solid rgb(175, 175, 175); */
}
.resume-card {
    box-shadow: 0 0 60px rgba(158, 158, 158, 0.2);
    border-radius: 6px;
}

.self-center {
    margin: 0 auto;
}

.margin-bottom-4 {
    margin-bottom: 14px;
}
.mania p {
    text-align: justify;
    font-size: 16px;
}
