
// .nav-link {
//     color: rgb(238, 255, 252) !important;
// }

.nav-shadow {
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.3);
}
.dropdown.nav-item {
    margin-left: 24px;
}
.navbar {
    padding-top: 20px;
    padding-bottom: 20px;
    transition: all 0.2s ease;
}
@media (max-width: 420px) {
    .navbar {
        background-color: #05695d;
    }
}
.navbar{
    box-shadow: 0px 4px 22px rgba($color: #04463e, $alpha: 0.3);

}
.navbar.scrolled {
    background-color: #075e54;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 0px 4px 22px rgba($color: #04463e, $alpha: 0.3);
}
